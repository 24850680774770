import React, { useContext, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import AnimationContext from "../contexts/animation-context";
import useProjectAnimation from "../hooks/useProjectAnimation";

gsap.registerPlugin(ScrollTrigger);

export default function ProjectSection({ project }) {
  const sectionRef = useRef(null);
  const textRef = useRef(null);
  const h5Ref = useRef(null);
  const h2Ref = useRef(null);
  const buttonRefs = useRef([]);
  const { setCurrentBg } = useContext(AnimationContext);

  const elementsRefs = { textRef, h5Ref, h2Ref, buttonRefs };

  useProjectAnimation(sectionRef, elementsRefs, project, setCurrentBg);

  return (
    <div ref={sectionRef} className="h-screen center">
      <div className="portfolioSection">
        <div className="logo mb-5">
          <div className={`imgProject img ${project.imageClass} wide`}></div>
        </div>
        <div className="flexLeft">
          <h5 ref={h5Ref}>{project.h5Text}</h5>
          <h2 ref={h2Ref}>{project.h2Text}</h2>
          <p ref={textRef}>{project.description}</p>
          <div className="buttonContainer">
            {project.buttons.map((button, index) => (
              <a
                href={button.href}
                target="_blank"
                rel="noreferrer"
                key={index}
              >
                <button
                  ref={(el) => (buttonRefs.current[index] = el)}
                  className="portfolioButton"
                >
                  <h5>
                    <button.icon />
                  </h5>
                  {button.label}
                </button>
              </a>
            ))}
          </div>
          <div className="project-languages">
            {project.languages.map((language) => (
              <div className="project-languages-container">
                <div className={`project-language ${language}`}></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
