import { useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function useProjectAnimation(
  sectionRef,
  elementsRefs,
  project,
  setCurrentBg
) {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "+=200 70%",
        end: "+=200 60%",
        scrub: true,
        pinSpacing: true,
        onEnter: () => {
          setCurrentBg(project.backgroundColor);
          gsap.to(elementsRefs.textRef.current, {
            color: project.colors.textColor,
            duration: 1,
          });
          gsap.to(elementsRefs.h5Ref.current, {
            color: project.colors.h5Color,
            duration: 1,
          });
          gsap.to(elementsRefs.h2Ref.current, {
            color: project.colors.h2Color,
            duration: 1,
          });
          project.buttons.forEach((_, index) => {
            gsap.to(elementsRefs.buttonRefs.current[index], {
              color: project.colors.buttonColor,
              borderColor: project.colors.buttonBorderColor,
              duration: 1,
            });
          });
        },
        onLeaveBack: () => {
          setCurrentBg(project.defaultBackgroundColor);
          gsap.to(elementsRefs.textRef.current, {
            color: project.defaultColors.textColor,
            duration: 1,
          });
          gsap.to(elementsRefs.h5Ref.current, {
            color: project.defaultColors.h5Color,
            duration: 1,
          });
          gsap.to(elementsRefs.h2Ref.current, {
            color: project.defaultColors.h2Color,
            duration: 1,
          });
          project.buttons.forEach((_, index) => {
            gsap.to(elementsRefs.buttonRefs.current[index], {
              color: project.defaultColors.buttonColor,
              borderColor: project.defaultColors.buttonBorderColor,
              duration: 1,
            });
          });
        },
      },
    });

    return () => {
      tl.scrollTrigger.kill(); // Clean up the ScrollTrigger
      tl.kill(); // Clean up the timeline
    };
    // eslint-disable-next-line
  }, [project, setCurrentBg]);
}
