import React, { useEffect, useRef, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Footer from "./components/Footer";
import "./App.css";
import { gsap } from "gsap/all";
import AnimationContext from "./contexts/animation-context";

function AppContent() {
  const appRef = useRef(null);
  const { currentBg } = useContext(AnimationContext);

  // Set the initial background color immediately on mount
  useEffect(() => {
    if (appRef.current) {
      gsap.set(appRef.current, { backgroundColor: currentBg });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (appRef.current) {
      gsap.to(appRef.current, {
        duration: 1,
        backgroundColor: currentBg,
        ease: "power2.inOut",
      });
    }
  }, [currentBg]);

  return (
    <div ref={appRef} className="pageContainer">
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default AppContent;
