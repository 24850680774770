import React from "react";
import { TextField } from "@mui/material";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import SocialIcons from "./Util/SocialIcons";
import ButtonMui from "./Util/ButtonMui";

// EmailJS config
const SERVICE_ID = "dantastic";
const TEMPLATE_ID = "template_dz3vjt6";
const PUBLIC_ID = "o-q-3XT-qC8hAq5v7";

const Contact = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };

  return (
    <>
      <div className="contactContainer">
        <div className="contact">
          <h2>Get in Touch</h2>
          <form onSubmit={handleOnSubmit} name="contact-form" method="POST">
            <TextField
              label="Name"
              name="name"
              placeholder="Harry Potter"
              required
              fullWidth
              type="text"
              variant="outlined"
            />
            <TextField
              label="Email"
              name="email"
              placeholder="harrypotter@hogwarts.com"
              required
              fullWidth
              margin="normal"
              type="email"
              variant="outlined"
            />
            <TextField
              label="Message"
              name="message"
              placeholder="Do you know anything about the chamber of secrets?"
              required
              fullWidth
              margin="normal"
              multiline
              rows={4}
              variant="outlined"
            />
            <ButtonMui
              type="submit"
              css="button"
              variant="contained"
              text="Send an Owl"
              target=""
            />
          </form>
        </div>
        <SocialIcons />
      </div>
    </>
  );
};

export default Contact;
