import React from "react";
import { SocialIcon } from "react-social-icons";

const SocialIcons = () => {
  return (
    <div className="infoContainer">
      <div className="infoText">
        <h2>Let's get in touch</h2>
        <p>I am open for any suggestion or just to have a chat</p>
      </div>
      <div className="iconContainer">
        <SocialIcon
          url="https://github.com/DantasticWebSolutions"
          fgColor="#fff"
          className="socialIcon"
          target="_blank"
          rel="noreferrer"
        />
        <h5>@dantasticWeb</h5>
      </div>
      <div className="iconContainer">
        <SocialIcon
          url="https://www.linkedin.com/in/gherasimciuc/"
          fgColor="#fff"
          className="socialIcon"
          target="_blank"
          rel="noreferrer"
        />
        <h5>@dangherasimciuc</h5>
      </div>
      <div className="iconContainer">
        <SocialIcon
          url="https://api.whatsapp.com/send?phone=+447984425836&text=Hello%20Dan,%20I%20would%20like%20to%20get%20in%20touch%20with%20you."
          fgColor="#fff"
          className="socialIcon"
          target="_blank"
          rel="noreferrer"
        />
        <h5>+44 79 844 258 36</h5>
      </div>

      {/* <div className="iconContainer">
        <SocialIcon
          url="https://www.instagram.com/dantastic_web/"
          fgColor="#fff"
          className="socialIcon"
          target="_blank"
          rel="noreferrer"
        />
        <h5>@dantastic_web</h5>
      </div> */}
    </div>
  );
};

export default SocialIcons;
