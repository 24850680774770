import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AnimationProvider } from "./contexts/animation-context";
import AppContent from "./AppContent";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-VHS8WGDPV0");
    ReactGA.send("pageview");
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3c9ee5",
      },
      secondary: {
        main: "#3c9ee5",
      },
    },
  });

  return (
    <AnimationProvider>
      <Router>
        <Suspense fallback="Loading">
          <ThemeProvider theme={theme}>
            <AppContent />
          </ThemeProvider>
        </Suspense>
      </Router>
    </AnimationProvider>
  );
}

export default App;
