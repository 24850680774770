import React from "react";
import Contact from "./Contact";
import "../index.css";
const Footer = () => {
  return (
    <>
      <div className="footerContainer">
        <div id="contact" className="footer">
          <Contact />
        </div>
        <a
          target="_blank"
          href="https://dantastic.dev/"
          className="poweredBy"
          rel="noreferrer"
        >
          <span>Dantastic Web Solutions &copy; 2024</span>
        </a>
      </div>
    </>
  );
};

export default Footer;
