import React from "react";
import TabSelection from "./TabSelection/TabSelection";
import projectsData from "../data/projectsData";
import ProjectSection from "./ProjectSection";
import About from "./Jobs/About";

export default function Home() {
  return (
    <div>
      <div>
        <About />
        {projectsData.map((project) => (
          <ProjectSection key={project.id} project={project} />
        ))}
        <TabSelection />
      </div>
    </div>
  );
}
