import React, { useState, useEffect, useRef } from "react";
import {
  angle1,
  angle2,
  angle3,
  angle4,
  angle5,
  angle6,
  angle7,
  angle8,
  angle9,
} from "./assets";
import throttle from "lodash/throttle";
import "./faceRotation.scss";

const Angles = [
  angle1,
  angle2,
  angle3,
  angle4,
  angle5,
  angle6,
  angle7,
  angle8,
  angle9,
];

const FaceRotation = () => {
  const [clientSize, setClientSize] = useState({
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  });
  const [pos, setPos] = useState(5);
  const containerRef = useRef(null);

  useEffect(() => {
    const setPosBasedOnX = (x) => {
      let calculatedPos = Math.ceil((x / clientSize.width) * 9);
      calculatedPos = Math.max(1, Math.min(9, calculatedPos)); // Ensure value is between 1 and 9
      setPos(calculatedPos);
    };
    const container = containerRef.current;

    // Throttled event handlers
    const handleHoverRotation = throttle((e) => {
      setPosBasedOnX(e.clientX);
    }, 50);

    const handleTouchRotation = throttle((e) => {
      if (e.touches.length === 1) {
        setPosBasedOnX(e.touches[0].clientX);
      }
    }, 50);

    const handleResize = throttle(() => {
      setClientSize({
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      });
      setPos(5);
    }, 500);

    // Adding event listeners
    container.addEventListener("mousemove", handleHoverRotation);
    container.addEventListener("touchmove", handleTouchRotation);
    window.addEventListener("resize", handleResize);

    // Cleanup listeners on component unmount
    return () => {
      container.removeEventListener("mousemove", handleHoverRotation);
      container.removeEventListener("touchmove", handleTouchRotation);
      window.removeEventListener("resize", handleResize);
    };
  }, [clientSize.width]);

  return (
    <div
      className="homeFace"
      style={{
        width: "100vw",
        padding: "30px 0",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
      ref={containerRef}
    >
      <div className="sagomaMaya bubble">
        {Angles.map((angle, index) => (
          <img
            alt="Dan"
            key={index}
            src={angle}
            style={{ display: pos === index + 1 ? "initial" : "none" }}
          />
        ))}
      </div>
    </div>
  );
};

export default FaceRotation;
