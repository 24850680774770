import React, { useEffect, useState } from "react";
import "../../index.css";
import "../../Timeline.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Education = () => {
  const [allPostsData] = useState([
    {
      date: "2021",
      img: "solent",
      name: "Solent University",
      title: "BSc Computer Science",
      description:
        "Web Technologies, Advanced Database, Java Object Oriented Programming, Backend Development, Cyber Security, Data Science",
      releaseDay: "2018",
      finishDay: "2021",
    },
    {
      date: "2013",
      img: "abba",
      name: "Istituto Tecnico Commerciale Statale Abba Ballini",
      title: "Sistemi Informativi Aziendali",
      description: "Italian A-Level equivalent in Business Management and IT",
      releaseDay: "2013",
      finishDay: "2018",
    },
  ]);

  const animateFromTo = (elem, direction = 1) => {
    let x = 0;
    let y = direction * 1000;

    if (elem.classList.contains("slide_from_left")) {
      x = -1000;
      y = 0;
    } else if (elem.classList.contains("slide_from_right")) {
      x = 1000;
      y = 0;
    } else if (elem.classList.contains("slide_from_up")) {
      y = -1000;
      x = 0;
    }

    gsap.fromTo(
      elem,
      { x, y, autoAlpha: 0 },
      {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
        stagger: 0.33,
      }
    );
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const elements = gsap.utils.toArray(".animate");

    elements.forEach((elem) => {
      gsap.set(elem, { autoAlpha: 0 });
    });

    elements.forEach((elem, index) => {
      ScrollTrigger.create({
        id: `education-scroll-${index}`,
        trigger: elem,
        onEnter: () => animateFromTo(elem),
        onLeaveBack: () => gsap.to(elem, { autoAlpha: 0, duration: 0.5 }),
      });
    });

    // Cleanup on component unmount
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => {
        if (trigger.vars.id && trigger.vars.id.startsWith("education-scroll")) {
          trigger.kill();
        }
      });
    };
  }, []);

  return (
    <div className="timeline">
      <ul>
        {allPostsData.map((te, index) => (
          <li key={`${te.date}-${index}`}>
            <div className="content">
              <div
                className={`imageWorkExperience slide_from_down animate ${te.img}`}
              ></div>
              <h5 className="slide_from_down animate uppercase">{te.name}</h5>
              <h5 className="slide_from_down animate">
                {te.releaseDay} -{" "}
                {te.finishDay === "" ? (
                  <span style={{ color: "var(--primary)" }}>PRESENT</span>
                ) : (
                  te.finishDay
                )}
              </h5>
              <h3 className="slide_from_down animate">{te.title}</h3>
              <p className="slide_from_down animate">{te.description}</p>
            </div>
            <div className="time slide_from_down animate">
              <h4>{te.date}</h4>
            </div>
          </li>
        ))}
        <div style={{ clear: "both" }}></div>
      </ul>
    </div>
  );
};

export default Education;
