// src/data/projectsData.js
import { FaGithub, FaRegEye } from "react-icons/fa";

const projectsData = [
  // First Project: Shc
  //

  // Second Project: Carmine
  {
    id: "carmine",
    backgroundColor: "#000",
    defaultBackgroundColor: "#222", // Previous component's background color (Shc)
    colors: {
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "rgb(233, 209, 255)",
      buttonColor: "rgb(233, 209, 255)",
      buttonBorderColor: "rgb(233, 209, 255)",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "rgb(233, 209, 255)",
      buttonColor: "rgb(233, 209, 255)",
      buttonBorderColor: "rgb(233, 209, 255)",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Serverless E-commerce",
    h2Text: "Carmine Sembra Brooklyn",
    description:
      "Developed a fully functional serverless e-commerce website leveraging Next.js, TypeScript, and Tailwind CSS for a responsive and dynamic frontend experience. Integrated Sanity CMS as a headless CMS for efficient product management and utilized Stripe for secure payment processing, implementing Stripe Checkout for seamless transactions. Employed Stripe webhooks to trigger confirmation emails upon successful payments, enhancing user engagement. Built RESTful APIs and utilized serverless functions to handle backend logic, ensuring scalability and performance. Implemented WebSockets for real-time communication between the frontend and backend, providing users with immediate updates and a dynamic shopping experience. This project showcases expertise in modern web development frameworks, serverless architecture, headless CMS integration, and real-time data handling.",
    imageClass: "carmine",
    buttons: [
      {
        href: "https://carmine-sembra-brooklyn.vercel.app/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
    languages: [
      "next",
      "typescript",
      "react",
      "tailwind",
      "sanity",
      "framer",
      "stripe",
      "sendgrid",
    ],
  },
  // Carmine Full stack
  {
    id: "carmine-full-stack",
    backgroundColor: "#000",
    defaultBackgroundColor: "#000", // Previous component's background color (Shc)
    colors: {
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "rgb(233, 209, 255)",
      buttonColor: "rgb(233, 209, 255)",
      buttonBorderColor: "rgb(233, 209, 255)",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "rgb(233, 209, 255)",
      buttonColor: "rgb(233, 209, 255)",
      buttonBorderColor: "rgb(233, 209, 255)",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Uni dissertation, Full-Stack E-commerce",
    h2Text: "Carmine Sembra Brooklyn",
    description:
      "Created a comprehensive full-stack e-commerce platform using the MERN stack—React.js with Redux for the frontend and Node.js, Express.js, and MongoDB for the backend. Implemented robust user authentication and authorization using JSON Web Tokens (JWT) and bcrypt.js for secure password hashing. Developed RESTful APIs to manage products, user profiles, orders, and shopping cart functionalities. Integrated PayPal's payment gateway via react-paypal-button-v2 for secure and efficient payment processing. Utilized Axios for API requests and React Router for seamless client-side navigation. Managed global state with Redux and handled asynchronous operations using Redux Thunk. Enabled image uploads and product management through Multer and Express.js. Enhanced the user interface with responsive design using Bootstrap and React-Bootstrap, and improved user interaction with components like Swiper for product carousels. Deployed the application to a scalable hosting platform, demonstrating proficiency in full-stack development, secure authentication, payment integration, and state management in complex web applications.",
    imageClass: "carmine",
    buttons: [
      {
        href: "https://github.com/DantasticWebSolutions/carmine-sembra-brooklyn",
        label: "Github",
        icon: FaGithub,
      },
    ],
    languages: [
      "mongo",
      "express",
      "react",
      "node",
      "redux",
      "paypal",
      "jwt",
      "bootstrap",
    ],
  },
  // Bryan GR
  {
    id: "bryan",
    backgroundColor: "#fff",
    defaultBackgroundColor: "#000",
    colors: {
      textColor: "var(--text-color)",
      h5Color: "#000",
      h2Color: "#ff6b00",
      buttonColor: "#ff6b00",
      buttonBorderColor: "#ff6b00",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Portfolio",
    h2Text: "Bryan Giraldo Portfolio",
    description:
      "Transformed a graphic designer's Figma design into a fully responsive and interactive website using React. Implemented smooth animations with Framer Motion and GSAP to enhance user experience. Ensured cross-browser compatibility and optimized performance for seamless viewing on all devices.",
    imageClass: "bryan",
    buttons: [
      {
        href: "https://bryangr.com",
        label: "Live",
        icon: FaRegEye,
      },
    ],
    languages: ["react", "gsap", "sass", "swiper"],
  },
  {
    id: "nicola",
    backgroundColor: "#000",
    defaultBackgroundColor: "#fff",
    colors: {
      textColor: "#fff",
      h5Color: "#fff",
      h2Color: "#cd0000",
      buttonColor: "#cd0000",
      buttonBorderColor: "#cd0000",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Portfolio",
    h2Text: "Nicola Rossi Portfolio",
    description:
      "Converted a custom Figma design into a dynamic portfolio website using Next.js and Tailwind CSS. Integrated interactive 3D elements with React Three Fiber and animations using Framer Motion. Delivered a fully responsive site that effectively showcases the designer's work across all platforms.",
    imageClass: "nicola",
    buttons: [
      {
        href: "https://portfolionicolarossi.netlify.app/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
    languages: ["react", "bootstrap", "gsap", "three", "swiper"],
  },
  {
    id: "austin",
    backgroundColor: "#dbe1c7",
    defaultBackgroundColor: "#000",
    colors: {
      textColor: "#7d826d",
      h5Color: "#000",
      h2Color: "#163a45",
      buttonColor: "#163a45",
      buttonBorderColor: "#163a45",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Portfolio",
    h2Text: "Caesar Music Portfolio",
    description:
      "Designed and developed a personal portfolio for an emerging musician and producer. Created the Figma design and built the website using React and Bootstrap. Integrated Spotify and SoundCloud embeds to feature music tracks directly on the site. Enhanced user engagement with interactive components and ensured full responsiveness.",
    imageClass: "austin",
    buttons: [
      {
        href: "https://caezarmusic.com/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
    languages: ["react", "bootstrap", "gsap", "css", "figma"],
  },
  {
    id: "nimbins",
    backgroundColor: "#f7f7f8",
    defaultBackgroundColor: "#dbe1c7",
    colors: {
      textColor: "#7d826d",
      h5Color: "#000",
      h2Color: "#4b4ea9",
      buttonColor: "#4b4ea9",
      buttonBorderColor: "#4b4ea9",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Website",
    h2Text: "Nimbin's Cafe",
    description:
      "Crafted a modern website for a coffeeshop in Brescia, Italy, to showcase their menu and opening times. Designed the layout in Figma and developed the site using React and Material-UI. Implemented engaging animations with GSAP and optimized the website for both desktop and mobile users.",
    imageClass: "nimbins",
    buttons: [
      {
        href: "https://nimbinscafe.com/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
    languages: ["react", "typescript", "gsap"],
  },
  {
    id: "cibo",
    backgroundColor: "#000",
    defaultBackgroundColor: "#f7f7f8",
    colors: {
      textColor: "#7d826d",
      h5Color: "#7d826d",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Website",
    h2Text: "Cibo Restaurant",
    description:
      "Collaborated with a graphic designer to develop a website for a classy Italian restaurant in West Kensington, London. Built the site using Next.js and Tailwind CSS, featuring a dynamic menu that staff can update daily via a secure backend portal. Ensured the website reflects the brand's elegance and provides a seamless user experience.",
    imageClass: "cibo",
    buttons: [
      {
        href: "https://cibonext.netlify.app/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
    languages: ["next", "typescript", "sanity", "bootstrap", "gsap"],
  },

  // Third Project: Meal
  {
    id: "meal",
    backgroundColor: "#99D2EA",
    defaultBackgroundColor: "#000", // Previous component's background color (Carmine)
    colors: {
      textColor: "#383838",
      h5Color: "#383838",
      h2Color: "#AD490B",
      buttonColor: "#AD490B",
      buttonBorderColor: "#AD490B",
      button2Color: "#AD490B",
      button2BorderColor: "#AD490B",
    },
    defaultColors: {
      // Colors matching the previous component (Carmine)
      textColor: "#000",
      h5Color: "#fff",
      h2Color: "rgb(233, 209, 255)",
      buttonColor: "rgb(233, 209, 255)",
      buttonBorderColor: "rgb(233, 209, 255)",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Serverless Web-App",
    h2Text: "Weekly Meal Planner",
    description:
      "Developed a meal planning web application aimed at helping users live healthier and more organized lives. Enabled recipe search, step-by-step cooking guides, calendar scheduling, and shopping list generation. Built with React, Firebase, and Bootstrap, enhancing user lifestyle through efficient meal management.",
    imageClass: "meal",
    buttons: [
      {
        href: "https://github.com/DantasticWebSolutions/weekly-planner",
        label: "Github",
        icon: FaGithub,
      },
      {
        href: "https://dantasticweeklyplanner.netlify.app/login",
        label: "Live",
        icon: FaRegEye,
      },
    ],
    languages: ["react", "bootstrap", "firebase"],
  },

  // Fourth Project: Map
  {
    id: "map",
    backgroundColor: "#CAE9FF",
    defaultBackgroundColor: "#52B6DE", // Previous component's background color (Meal)
    colors: {
      textColor: "#3F8098",
      h5Color: "#5FA8D3",
      h2Color: "#1B4965",
      buttonColor: "#1B4965",
      buttonBorderColor: "#1B4965",
      button2Color: "#5FA8D3",
      button2BorderColor: "#5FA8D3",
    },
    defaultColors: {
      // Colors matching the previous component (Meal)
      textColor: "#fff",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Uni Assessment, Back-End Development",
    h2Text: "Searchable Map",
    description:
      "Created an open-source map application allowing users to perform CRUD operations on custom map locations. Implemented user authentication for personalized accounts using Express, MongoDB, and Passport.js. Enabled users to keep track of favorite spots worldwide by adding detailed information to each map point.",
    imageClass: "map",
    buttons: [
      {
        href: "https://github.com/DantasticWebSolutions/pointOfInterest",
        label: "Github",
        icon: FaGithub,
      },
    ],
    languages: ["express", "mongo", "mongoose", "passport", "hbs"],
  },

  // Fifth Project: Expenses
  {
    id: "expenses",
    backgroundColor: "#fff",
    defaultBackgroundColor: "#CAE9FF", // Previous component's background color (Map)
    colors: {
      textColor: "#3F8098",
      h5Color: "#5FA8D3",
      h2Color: "#1B4965",
      buttonColor: "#1B4965",
      buttonBorderColor: "#1B4965",
      button2Color: "#5FA8D3",
      button2BorderColor: "#5FA8D3",
    },
    defaultColors: {
      // Colors matching the previous component (Map)
      textColor: "#3F8098",
      h5Color: "#5FA8D3",
      h2Color: "#1B4965",
      buttonColor: "#1B4965",
      buttonBorderColor: "#1B4965",
      button2Color: "#5FA8D3",
      button2BorderColor: "#5FA8D3",
    },
    h5Text: "Uni Assessment, Back-End Development",
    h2Text: "Expenses Tracker",
    description:
      "Developed a web application for tracking personal expenses and monitoring account balances. Built using Express.js and MongoDB, the application allows users to log expenses and visualize their spending habits. Ensured data integrity and security through robust server-side logic.",
    imageClass: "expensesTracker",
    buttons: [
      {
        href: "https://github.com/DantasticWebSolutions/dantastic-expense-tracker",
        label: "Github",
        icon: FaGithub,
      },
    ],
    languages: ["express", "mongo", "mongoose"],
  },
];

export default projectsData;
