// src/components/Jobs/About.js
import React, { useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import AnimationContext from "../../../contexts/animation-context";
import FaceRotation from "../../FaceRotation";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import cv from "../../FaceRotation/assets/Dan_Gherasimciuc_CV.pdf";

gsap.registerPlugin(ScrollTrigger);

// Reusable Social Link Component
const SocialLink = ({ href, icon: Icon, label }) => (
  <a href={href} target="_blank" rel="noreferrer" aria-label={label}>
    <h5>
      <Icon className="social-icon" />
    </h5>
  </a>
);

export default function About() {
  const sectionRef = useRef(null);
  const textRef = useRef(null);
  const h2Ref = useRef(null);
  const { setCurrentBg } = useContext(AnimationContext);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "+=80% 70%",
          end: "+=200 60%",
          scrub: true,
          pinSpacing: true,
          onEnter: () => {
            setCurrentBg("#222");
            gsap.to(textRef.current, { color: "#b9b9b9", duration: 1 });
            gsap.to(h2Ref.current, { color: "#fff", duration: 1 });
          },
          onEnterBack: () => {
            setCurrentBg("#fff");
            gsap.to(textRef.current, { color: "var(--text)", duration: 1 });
            gsap.to(h2Ref.current, { color: "#000", duration: 1 });
          },
        },
      });
    }, sectionRef);

    return () => ctx.revert(); // Proper cleanup on unmount
  }, [setCurrentBg]);

  return (
    <div ref={sectionRef} className="h-screen center">
      <div className="homePage">
        <div className="home">
          <h5>Software Engineer</h5>
          <h2 ref={h2Ref}>
            Hello, I am <span className="primaryColor">Dan</span>tastic
          </h2>
          <p ref={textRef}>
            a front-end developer with a strong full-stack background,
            specializing in creating high-performance, responsive web
            applications using React, Next, TypeScript, and JavaScript.
          </p>

          {/* Reusable Social Links */}
          <div className="buttonContainer">
            <SocialLink
              href="https://github.com/DantasticWebSolutions"
              icon={FaGithub}
              label="Github"
            />
            <SocialLink
              href="https://www.linkedin.com/in/gherasimciuc/"
              icon={FaLinkedin}
              label="LinkedIn"
            />
            <SocialLink href={cv} icon={BsFillFileEarmarkPdfFill} label="CV" />
          </div>
        </div>
        <FaceRotation />
      </div>
    </div>
  );
}
