import { createContext, useState } from "react";

const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
  const [currentBg, setCurrentBg] = useState("#fff");

  return (
    <AnimationContext.Provider value={{ currentBg, setCurrentBg }}>
      {children}
    </AnimationContext.Provider>
  );
};

export default AnimationContext;
