import React, { useEffect, useState } from "react";
import "../../index.css";
import "../../Timeline.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const WorkExperience = () => {
  const [allPostsData] = useState([
    {
      date: "October 2024",
      img: "saville",
      name: "Saville Assessment Ltd",
      title: "Software Engineer",
      address: "London, UK",
      description:
        "Optimized .NET, C#, React and Typescript code and generated reusable UI components from Figma designs. I spearheaded the development of dashboards and platform pages, driving code consistency across the enterprise SaaS system and executing high-performance, scalable solutions.",
      releaseDay: "11/2022",
      finishDay: "10/2024",
    },
    {
      date: "November 2022",
      img: "shc",
      name: "Suisse Health Company SA",
      title: "Full-Stack Developer",
      address: "Chiasso, CH",
      description:
        "Engineered responsive web applications using the MERN stack, enhancing development speed by 15%. I implemented robust authentication systems, integrated APIs, and optimized state management, driving performance improvements and delivering secure, scalable solutions across multiple projects.",
      releaseDay: "06/2018",
      finishDay: "12/2022",
    },
    {
      date: "July 2017",
      img: "gulliver",
      name: "Gulliver srl",
      title: "JavaScript Developer Internship",
      address: "Brescia, IT",
      description:
        "Designed and developed innovative UI interfaces and mobile applications using Gulliver's MVC platform, enhancing user engagement and improving overall user experience. I supported the development team, executing key tasks that contributed to streamlined project workflows.",
      releaseDay: "04/2017",
      finishDay: "07/2017",
    },
    {
      date: "June 2016",
      img: "inps",
      name: "National Social Insurance Agency (INPS)",
      title: "Releasing SPID Credentials Internship",
      address: "Brescia, IT",
      description:
        "Facilitated customer onboarding by assisting with account creation and SPID credential registration, enabling access to all Italian government platforms. I streamlined the registration process, ensuring compliance and enhancing user accessibility",
      releaseDay: "05/2016",
      finishDay: "06/2016",
    },
  ]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const elements = gsap.utils.toArray(".animate");

    // gsap.set(elements, { autoAlpha: 0, y: 200 });
    elements.forEach((elem) => {
      gsap.set(elem, { autoAlpha: 0, y: 200 });
    });

    elements.forEach((elem, index) => {
      ScrollTrigger.create({
        id: `work-experience-scroll-${index}`,
        trigger: elem,
        onEnter: () =>
          gsap.fromTo(
            elem,
            {
              y: 200,
              autoAlpha: 0,
            },
            {
              autoAlpha: 1,
              duration: 0.9,
              y: 0,
              ease: "expo",
            }
          ),
        onLeaveBack: () =>
          gsap.to(elem, {
            autoAlpha: 0,
            duration: 0.9,
            y: 200,
            ease: "expo",
          }),
      });
    });

    ScrollTrigger.refresh();

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => {
        if (
          trigger.vars.id &&
          trigger.vars.id.startsWith("work-experience-scroll")
        ) {
          trigger.kill();
        }
      });
    };
  }, []);

  return (
    <div className="timeline">
      <ul>
        {allPostsData.map((te, index) => (
          <li key={`${te.date}-${index}`}>
            <div className="content">
              <div
                className={`imageWorkExperience animate hidden-start ${te.img}`}
              ></div>
              <h5 className="animate hidden-start">{te.name}</h5>
              <h5 className="animate hidden-start">
                {te.releaseDay} - {te.finishDay}
              </h5>
              <h3 className="animate hidden-start">{te.title}</h3>
              <h5 className="animate hidden-start">{te.address}</h5>
              <p className="animate hidden-start">{te.description}</p>
            </div>
            <div className="time animate hidden-start">
              <h4>{te.date}</h4>
            </div>
          </li>
        ))}
        <div style={{ clear: "both" }}></div>
      </ul>
    </div>
  );
};

export default WorkExperience;
